const MenuSingleItem = (props) => {

    const item = props.value;

    return (
        <div className='menu-card-body' key={props.id}>
            <img src={item.image} alt={item.name} />
            <div className='menu-card-details'>
                <h3>{item.name}</h3>
                <p className='description'>{item.description}</p>
                <div>
                    <p className='price'><b>Solo:</b> {item.priceSolo}</p>
                    <p className='price'><b>Share:</b> {item.priceParty}</p>
                </div>
            </div>
        </div>
    )
}

export default MenuSingleItem;
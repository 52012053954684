const Footer = () => {
    return (
        <div className='container'>
            <div className='footer'>
                <div className='footer-container'>
                    <div>
                        <h3>Social Media</h3>
                        <p>Facebook</p>
                        <p>Instagram</p>
                        <p>WhatsApp</p>
                    </div>
                    <div>
                        <h3>Links</h3>
                        <p><a href='#menu'>Menu</a></p>
                        <p><a href='#special'>Specials</a></p>
                        <p><a href='#contact'>Contact</a></p>
                        <p><a href='#about'>About</a></p>
                    </div>
                    <div>
                        <h3>Need a Website?</h3>
                        <a href='https://www.facebook.com/simplecodesph'>SimpleCodesPH</a>
                        <h3>Looking for Properties?</h3>
                        <a href='https://www.sm-residences.com'>SM Residences</a>
                    </div>
                </div>
            </div>
            <div className='copyright'>
                <p>charmsfoodies.com is owned by SimpleCodesPH</p>
            </div>
        </div>
    )
}

export default Footer;
const SpecialsData = ({ value }) => {

    const specialsData = value.map((item, index) => (
        <div className='specials-card-body' key={index}>
            <img src={item.image} alt={item.name} />
            <div className='specials-card-details'>
                <h3>{item.name}</h3>
                <p>{item.description}</p>
            </div>
        </div>
    ));

    return (
        <>
            <div className='specials'>
                <h1>Specials</h1>
                <div className='specials-data'>
                    {specialsData}
                </div>
                <p>scroll down to see the menu</p>
                <i className="fa-solid fa-angles-down arrow-down"></i>
            </div>
        </>
    )
}

export default SpecialsData;
import { useContext } from "react";
import { MyContext } from "../../Context";

const MenuButtons = (props) => {

    const context = useContext(MyContext);
    const handleMenuButtonNames = context.handleMenuButtonNames;
    const buttonMenuActive = context.state.buttonMenuActive;
    const item = props.value;
    const index = props.id;
    const itemUpperCase = item.charAt(0).toUpperCase() + item.slice(1);

    return (
        <>
            <button
                className={(buttonMenuActive === parseInt(index) ? 'menu-button-active' : 'menu-button-default')}
                onClick={handleMenuButtonNames}
                name={item}
                key={index}
                id={index}
            >
                {itemUpperCase}
            </button>
        </>
    )
}

export default MenuButtons;
const BannerAbout = () => {
    return (
        <div className='banner-about'>
            <div className='banner-about-info'>
                <div>
                    <h1>Charm's Foodies</h1>
                    <h2>Home-cooked filipino favorites</h2>
                    <h3>ready to order?</h3>
                </div>
            </div>
        </div>
    )
}

export default BannerAbout;
import { useContext } from "react";
import { MyContext } from "../../Context";

const Menu = () => {

    const context = useContext(MyContext);
    const handleMenuButtons = context.handleMenuButtons;
    const handleMenuSingleItem = context.handleMenuSingleItem;

    return (
        <div className='container' id='menu'>
            <div className='menu'>
                <h1>Menu</h1>
                <div className='menu-nav'>
                    {handleMenuButtons()}
                </div>
                <div className='menu-content'>
                    {handleMenuSingleItem()}
                </div>
            </div>
        </div>
    )
}

export default Menu;
const BannerMenu = () => {
    return (
        <div className='banner-menu'>
            <div className='banner-menu-info'>
                <div>
                    <h1>Filipino Favorites</h1>
                    <h3>enjoy our mouth watering selection.</h3>
                    <a href='#menu'>Menu</a>
                </div>
            </div>
        </div>
    )
}

export default BannerMenu;
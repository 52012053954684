import { useContext, useEffect, useRef } from 'react';
import { MyContext } from '../Context';
import logo from './Images/charms-foodies-black-on-transparent-background.png'
const Navbar = () => {

    const refOne = useRef(null);
    const context = useContext(MyContext);
    const state = context.state;
    const handleBurgerIcon = context.handleBurgerIcon;
    const handleMobileNavbar = context.handleMobileNavbar;
    const handleOnclickNavLinks = context.handleOnclickNavLinks;
    const handleOnClickNavbarWithRef = context.handleOnClickNavbarWithRef;

    useEffect(() => { // to prevent the screen from scrolling when nav is openned.
        state.burgerIconState
            ? document.body.style.overflow = 'hidden'
            : document.body.style.overflow = 'scroll';
    }, [state.burgerIconState]);

    useEffect(() => { // handle clicks outside of nabar to close the navbar if navbar is visible
        document.addEventListener('click', handleClickOutsite, true)
        // eslint-disable-next-line
    }, []);

    const handleClickOutsite = (e) => {
        if (!refOne.current.contains(e.target)) {
            console.log('click outside navbar');
            handleOnClickNavbarWithRef();
        }
        else {
            console.log('click inside navbar');
        }
    }

    return (
        <div className='container' ref={refOne}>
            <div className='navbar'>
                <nav>
                    <div className='logo'>
                        <img src={logo} alt='Charms-Foodies' />
                    </div>
                    <div onClick={handleBurgerIcon} className='burger-icon-active'>
                        <i className="fa-solid fa-bars"></i>
                    </div>
                    <div className={handleMobileNavbar()}>
                        <a href='#menu' onClick={handleOnclickNavLinks}><p>Menu</p></a>
                        <a href='#special' onClick={handleOnclickNavLinks}><p>Specials</p></a>
                        <a href='#contact' onClick={handleOnclickNavLinks}><p>Contact Us</p></a>
                        <a href='#about' onClick={handleOnclickNavLinks}><p>About</p></a>
                        <h5>Need a website?</h5>
                        <div>
                            {/* <i className="fa-brands fa-facebook-f"></i> */}
                            <a href='https://www.facebook.com/simplecodesph'><p>SimpleCodesPH</p></a>
                        </div>
                        <h5>Looking for properties?</h5>
                        <div className='sm-residences-links'>
                            <p>SM Residences</p>
                            <a href='https://www.facebook.com/smresidencesbygeraldinegarcia'><i className="fa-brands fa-facebook-f"></i></a>
                            <a href='https://www.sm-residences.com'><i className="fa-solid fa-globe"></i></a>
                        </div>
                    </div>
                    <div className='nav-links-active'>
                        <a href='#menu'><p>Menu</p></a>
                        <a href='#special'><p>Specials</p></a>
                        <a href='#contact'><p>Contact Us</p></a>
                        <a href='#about'><p>About</p></a>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default Navbar;
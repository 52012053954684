import { useContext } from "react";
import { MyContext } from "../../Context";

const Specials = () => {

    const context = useContext(MyContext);
    const handleSpecialsData = context.handleSpecialsData;

    return (
        <div className='container' id='special'>
            {handleSpecialsData()}
        </div>
    )
}

export default Specials;
import BannerAbout from './BannerComponents/BannerAbout';
import BannerMenu from './BannerComponents/BannerMenu';
import BannerContact from './BannerComponents/BannerContact';
import Carousel from 'nuka-carousel';

const Banner = () => {

    const carouselParams = {
        autoplay: true,
        autoplayInterval: 5000,
        pauseOnHover: true,
        swiping: true,
        wrapAround: true,
        speed: 1000,
        frameAriaLabel: '',
        animation: '',
        adaptiveHeight: false,
        withoutControls: true,
    }

    return (
        <div className='container'>
            <div className='banner'>
                <Carousel {...carouselParams}
                    renderCenterLeftControls={() => null}
                    renderCenterRightControls={() => null}
                >
                    <BannerAbout />
                    <BannerMenu />
                    <BannerContact />
                </Carousel>
            </div>
        </div>
    )
}

export default Banner;
import { MyProvider } from './Context';
import Navbar from './Components/Navbar';
import Banner from './Components/Banner';
import Specials from './Components/SpecialsComponents/Specials';
import Menu from './Components/MenuComponents/Menu';
import About from './Components/About';
import ContactUs from './Components/ContactUs';
import Footer from './Components/Footer';
import './Styles/style.css';

const App = () => {

  return (
    <MyProvider>
      <Navbar />
      <Banner />
      <Specials />
      <Menu />
      <About />
      <ContactUs />
      <Footer />
    </MyProvider>
  );
}
export default App;

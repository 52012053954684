import { useContext } from "react";
import { MyContext } from "../Context";

const ContactUs = () => {
  const context = useContext(MyContext);
  const handleSubmitButton = context.handleSubmitButton;
  const handleInputNameClassName = context.handleInputNameClassName;
  const handleInputEmailClassName = context.handleInputEmailClassName;
  const handleInputMobileClassName = context.handleInputMobileClassName;
  const handleInputMessageClassName = context.handleInputMessageClassName;
  const handleInputOnChange = context.handleInputOnChange;
  const inputStatus = context.inputStatus;
  console.log(inputStatus);

  return (
    <div className="container" id="contact">
      <h1>Contact Us</h1>
      <div className="contact">
        <div className="contact-info">
          <div>
            <p>
              For any events and reservations just send us your questions or
              concerns and we will give you the help you need.{" "}
            </p>
            <p>
              All inquiries can also be sent through Facebook Chat or call
              directly.
            </p>
          </div>
          <div className="contact-icons">
            <div>
              <i className="fa-brands fa-facebook-f"></i>
              <a href="https://facebook.com/charmsfoodies">
                <p>Charm's Foodies</p>
              </a>
            </div>
            <div>
              <i className="fa-brands fa-whatsapp"></i>
              <p>0947 891 6371</p>
            </div>
            <div>
              <i className="fa-solid fa-envelope"></i>
              <p>charmilyn.llanera@gmail.com</p>
            </div>
            <div>
              <i className="fa-solid fa-phone"></i>
              <p>0947 891 6371</p>
            </div>
          </div>
        </div>
        <div className="contact-input">
          <form>
            <input
              className={handleInputNameClassName()}
              onChange={handleInputOnChange}
              type="text"
              placeholder="Name"
              name="inputName"
            ></input>
            <input
              className={handleInputEmailClassName()}
              onChange={handleInputOnChange}
              type="text"
              placeholder="Email"
              name="inputEmail"
            ></input>
            <input
              className={handleInputMobileClassName()}
              onChange={handleInputOnChange}
              type="number"
              placeholder="Contact"
              name="inputMobile"
            ></input>
            <textarea
              className={handleInputMessageClassName()}
              onChange={handleInputOnChange}
              type="text"
              placeholder="Message"
              name="inputMessage"
            />
            <button className={handleSubmitButton()}>Send</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

const BannerContact = () => {
    return (
        <div className='banner-contact'>
            <div className='banner-contact-info'>
                <div>
                    <h1>Contact Us</h1>
                    <h2>we are here to assist you</h2>
                    <a href='#contact'>Send us an Email</a>
                </div>
            </div>
        </div>
    )
}

export default BannerContact;
import React, { Component } from 'react';
import foodJSON from '../food.json';
import SpecialsData from '../Components/SpecialsComponents/SpecialsData';
import MenuButtons from '../Components/MenuComponents/MenuButtons';
import MenuSingleItem from '../Components/MenuComponents/MenuSingleItem';

const MyContext = React.createContext();

class MyProvider extends Component {

    state = {
        foodJSON: foodJSON,
        buttonName: 'baked',
        buttonMenuActive: 7,
        submitButton: false,
        inputName: '',
        inputNameStatus: false,
        inputEmail: '',
        inputEmailStatus: false,
        inputMobile: 0,
        inputMobileStatus: false,
        inputMessage: '',
        inputMessageStatus: false,
        inputStatus: false,
        burgerIconState: false,
    }

    handleOnclickNavLinks = () => {
        this.setState({ burgerIconState: !this.state.burgerIconState });
    }

    handleOnClickNavbarWithRef = () => {
        this.setState({ burgerIconState: false });
    }

    handleMobileNavbar = () => {
        return (this.state.burgerIconState ?
            'nav-links-mobile-active' :
            'nav-links-mobile-notActive'
        );
    }

    handleBurgerIcon = () => {
        const burgerIconState = this.state.burgerIconState;
        this.setState({ burgerIconState: !burgerIconState });
    }

    checkAllInputs = () => {

    }

    handleInputOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleInputNameClassName = (e) => {
        return (this.state.inputName.length >= 3 ? 'input-true' : 'input-false');
    }

    handleInputEmailClassName = () => {
        return (this.state.inputEmail.includes('@') ? 'input-true' : 'input-false');
    }

    handleInputMobileClassName = () => {
        return (this.state.inputMobile.length === 11 ? 'input-true' : 'input-false');
    }

    handleInputMessageClassName = () => {
        return (this.state.inputMessage.length >= 50 ? 'input-true' : 'input-false')
    }

    handleSubmitButton = () => {
        const submitButton = this.state.submitButton;
        return (submitButton ? 'contact-button-true' : 'contact-button-false');
    }

    handleSpecialsData = () => {
        const foodJSON = this.state.foodJSON;
        const activeFood = foodJSON.find((item) => Object.keys(item).includes('speciality'));
        const specials = Object.values(activeFood)[0];
        return <SpecialsData value={specials} />
    }

    handleMenuButtons = () => {
        const foodJSON = this.state.foodJSON;
        const buttonObject = foodJSON.map((food) => {
            return Object.keys(food)[0];
        });
        return buttonObject.map((button, index) => (
            <MenuButtons value={button} key={index} id={index} />
        ))
    }

    handleMenuSingleItem = () => {
        const foodJSON = this.state.foodJSON;
        const buttonName = this.state.buttonName;
        const activeFood = foodJSON.find((item) => Object.keys(item).includes(buttonName));
        return Object.values(activeFood)[0].map((food, index) => (
            <MenuSingleItem value={food} key={index} />
        ));
    }

    handleMenuButtonNames = (e) => {
        const buttonName = e.target.name;
        this.setState({ buttonName: buttonName, buttonMenuActive: parseInt(e.target.id) });
    }

    render() {

        const
            {
                state,
                handleSpecialsData, handleMenuButtons, handleMenuSingleItem, handleMenuButtonNames,
                handleSubmitButton, handleInputNameClassName, handleInputEmailClassName, handleInputOnChange,
                handleInputMobileClassName, handleInputMessageClassName, handleBurgerIcon, handleMobileNavbar,
                handleOnclickNavLinks, handleActiveMenuButton, handleOnClickNavbarWithRef,
            } = this;

        return (
            <MyContext.Provider
                value={{
                    state,
                    handleSpecialsData, handleMenuButtons, handleMenuSingleItem, handleMenuButtonNames,
                    handleSubmitButton, handleInputNameClassName, handleInputEmailClassName, handleInputOnChange,
                    handleInputMobileClassName, handleInputMessageClassName, handleBurgerIcon, handleMobileNavbar,
                    handleOnclickNavLinks, handleActiveMenuButton, handleOnClickNavbarWithRef,
                }}>
                {this.props.children}
            </MyContext.Provider>
        )
    }
}

export { MyContext, MyProvider };